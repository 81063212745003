import React from "react";

export function Section(props) {
	return props.sectionFields.length > 0 && (
		<div className="section-wrapper" key={props.sectionName} data-sectionname={props.sectionName.toLowerCase().replace(/\s/g, "_").replace(/[^a-z_]/g, "")}>
			{!props.reader && (props.sectionName === "On-Site Design" || props.sectionName === "Emails") && (
				<div
					className={"add-group-wrapper"}
					data-id={props.sectionName}
					onClick={props._handleAddGroupButtonClicked}
				>
					<p className="noselect">Create Group</p>
					<span>&#43;</span>
				</div>
			)}
			<div className="title-container">
				<h3 data-id={props.sectionName}>
					{props.sectionName}
				</h3>
			</div>
			<div className={"section-fields-wrapper"} id={props.sectionName + "_field_wrapper"}>
				{/* Render ungrouped fields */}
				{props.renderFields(props.sectionFields, props.form)}
				{/* Render grouped fields */}
				{props.attemptArr.map((attempt, idx) => {
					let attemptNum = idx + 1;

					if (attempt[0] && attempt[0].fields && attempt[0].fields[0] && attempt[0].fields[0].content) {
						attemptNum = attempt[0].fields[0].content;
					}

					return (
						<div
							className={"section-group-attempt-wrapper attempt" + (idx + 1)}
							key={"attempt" + (idx + 1)}
						>
							<h3>
								{props.groupTitle} {attemptNum}
							</h3>
							{attempt.map((item) => {
								return (
									<div className={"section-group-wrapper"} key={item.group_id}>
										{!props.reader && (
											<div
												className={"delete-group-wrapper"}
												data-id={item.group_id}
												data-section={props.sectionName}
												onClick={props._handleDeleteGroupButtonClicked}
											>
												<p className="noselect">Delete Group</p>
												<span>&#215;</span>
											</div>
										)}
										<div className="group-title">
											{item.display_title || item.group_title}
											<span className="order">{" • " + item.num}</span>
										</div>
										<div className="group-content">
											{props.renderFields(item.fields, props.form)}
										</div>
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
		</div>
	);
}
