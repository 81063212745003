let exists = (str) => {
	return (
		str && str !== "0" && str !== "100%" && str !== "- TBD -" && str !== "All" && str !== "Launch on all products"
	);
};
const parseBlocks = (str) => {
	try {
		return str && JSON.parse(str) && JSON.parse(str).blocks && JSON.parse(str).blocks.map((a) => a.text).join(" ")
	} catch(e) {
		return str;
	}
};

export const field_data = {
	summary: {
		title: "Summary",
		description:
			"Clearly and concisely describe the campaign while avoiding duplicating information elsewhere in the CSD"
	},
	site_ids: {
		title: "Site ID(s)",
		description: "List the Admin Site IDs related to this CSD (Comma separated - Ex: 12345, 67890, 54321... etc)"
	},
	campaign_name: {
		title: "Preferred Campaign Name",
		description: "Provide the name for the end of the Site name."
	},
	config_ids: {
		title: "Config ID(s)",
		description: "List the Admin Config IDs related to this CSD (Comma separated - Ex: 12345, 67890, 54321... etc)"
	},
	email_ids: {
		title: "LC Email Site ID(s)",
		description:
			"List the LC's Email Site IDs related to this CSD (Comma separated - Ex: 12345, 67890, 54321... etc)"
	},
	tags: {
		title: "Tags",
		description: "Any keywords you would like to use for searching",
		selections: ["Archived", "Live", "On Hold", "Template"]
	},
	language: {
		estimate_time: (c) => (exists(c) ? c.split("\n").length * 2 : 0),
		title: "Language(s)",
		description: "",
		selections: [
			"English",
			"Czech",
			"Danish",
			"Dutch",
			"Finnish",
			"French",
			"German",
			"Greek",
			"Hebrew",
			"Hungarian",
			"Indonesian",
			"Italian",
			"Japanese",
			"Korean",
			"Latvian",
			"Lithuanian",
			"Mandarin (Chinese)",
			"Norwegian",
			"Persian",
			"Polish",
			"Portuguese",
			"Romanian",
			"Russian",
			"Spanish",
			"Swedish",
			"Thai",
			"Turkish",
			"Ukrainian",
			"Vietnamese",
			"Welsh"
		]
	},
	lead_source: {
		title: "Lead Source",
		description: "How will we obtain or target users' email addresses?",
		placeholder: "",
		selections: [
			"Consumer Database+ | UpsellitWrapper",
			"Abandoned Form Only | Client Branding",
			"Returning Visitor ID | Client Branding",
			"Client Datapass | Client Branding"
		]
	},
	strategy_type: {
		title: "Strategy Type",
		description: "What is the nature of the solution we are building?",
		placeholder: "",
		selections: [
			"Reiteration (No Incentive)",
			"Incentive",
			"Low Stock Prompt (No Incentive)",
			"Save Your Cart (Cart Rebuilder)",
			"In-Stock Alert (LC Only)",
			"Coupon Corrector",
			"Ad Extension Manager",
			"Coupon Analytics",
			"Recommend: Cross-Sell Complimentary Items",
			"Recommend: Cross-Sell Alternative Items",
			"Recommend: Downsell Recs",
			"Recommend: Upsell",
			"Recommend: Targeted Subtotal",
			"Recommend: Low Stock Alerts",
			"Recommend: Popular Picks",
			"Recommend: Bestsellers",
			"Recommend: Personalized/Targeted Recs",
			"Recommend: Client's choice",
			"Recommend: Discount Deals",
			"Recommend: Back in stock modal",
			"Recommend: Previously Viewed",
			"Recommend: Replenishment reminders",
			"Recommend: Bundling",
			"Recommend: Seasonal/Event-Based",
			"Recommend: Location-Based",
			"Recommend: Product Comparison",
			"Recommend: New Arrivals",
			"Recommend: Custom"
		]
	},
	ce_type: {
		title: "CE Strategy Type",
		description: "What is the nature of the solution we are building?",
		selections: [
			"Select Type",
			"Coupon Corrector",
			"Ad Extension Manager",
			"Coupon Analytics",
		]
	},
	incentive_type: {
		title: "Incentive Type",
		description: "Do we provide the user with a coupon code?",
		selections: ["No Incentive", "Multi Use Coupon", "Single Use Coupon (Coupon List)"]
	},
	coupon_code: {
		validate: (c) => (parseBlocks(c) !== "" ? "Confirm coupon works or note if client will activate" : "Please add coupon or list"),
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Coupon Code / List",
		description: "List the coupon code or link to a csv or txt file with coupon codes"
	},
	conversion_tracking: {
		estimate_time: (c) => (exists(c) ? 1 : 0),
		title: "Conversion Tracking",
		description: "When should a conversion, lead or revenue be attributed to USI?",
		placeholder: "- TBD -",
		selections: [
			"Affiliate (on CTA click)",
			"USI Internal (on CTA click)",
			"Only when recommended item is in cart at checkout",
			"Only Enhanced Revenue is billable",
			"No Tracking"
		]
	},
	affiliate_link: {
		title: "Affiliate Link",
		description: ""
	},
	sale_window: {
		validate: (c) => (Number(c) <= 0 ? "This would prevent sale tracking. Enter positive number." : ""),
		title: "Sale Window",
		description: "Time after link is clicked that we count a sale as ours",
		selections: ["1 Week", "2 Weeks", "30 Days"]
	},
	desktop_launch: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Desktop Launch",
		description: "Do we launch this solution on desktop web browsers?"
	},
	desktop_launch_method: {
		title: "Desktop Launch Method",
		description: "",
		selections: [
			"Abandonment",
			"Proactive (Modal)",
			"Proactive (In-Page)",
			"Abandonment & Proactive",
			"Test Abandonment vs Proactive",
			"User Action Based (On Add-To-Cart)",
			"Custom"
		]
	},
	desktop_launch_action: {
		title: "Desktop Launch Action",
		description: "What does the user need to do on the page for our modal to display?",
		selections: ["Add to Cart"]
	},
	desktop_launch_aggressiveness: {
		title: "Launch Aggressiveness",
		description: "",
		selections: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
	},
	desktop_traffic_exposure_percentage: {
		estimate_time: (c) => (exists(c) ? 1 : 0),
		title: "Desktop Traffic Exposure %",
		description: "What percentage of users are we launching this solution for?",
		selections: ["10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%", "100%"]
	},
	mobile_is_ab_test: {
		title: "Mobile AB test",
		description: "Should we track eligible and control groups in Client Reporting?",
		wiki: "/wiki/split_tests__lift_tests"
	},
	desktop_is_ab_test: {
		title: "Desktop AB Test",
		description: "Should we track eligible and control groups in Client Reporting?",
		wiki: "/wiki/split_tests__lift_tests"
	},
	desktop_is_incremental_lift: {
		title: "Is Incremental Lift",
		description: "Should we track eligible and control groups in Client Reporting?",
		wiki: "/wiki/split_tests__lift_tests"
	},
	mobile_launch: {
		title: "Mobile Launch",
		description: "Do we launch this solution on mobile web browsers?"
	},
	mobile_launch_method: {
		title: "Mobile Launch Method",
		description: "",
		selections: [
			"Back Off Page",
			"Back Off Site",
			"Quick Scroll",
			"Proactive (Modal)",
			"Proactive (In-Page)",
			"User Action Based (On Add-To-Cart)",
			"Custom"
		]
	},
	mobile_launch_aggressiveness: {
		title: "Launch Aggressiveness",
		description: "",
		selections: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
	},
	mobile_launch_action: {
		title: "Mobile Launch Action",
		description: "What does the user need to do on the page in order for our modal to display?",
		selections: ["Add to Cart"]
	},
	mobile_traffic_exposure_percentage: {
		estimate_time: (c) => (exists(c) ? 1 : 0),
		title: "Mobile Traffic Exposure %",
		description:
			"What percentage of users are we launching this solution for? Anything less than 100% usually means it's an Incremental Lift Test",
		selections: ["10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%", "100%"]
	},
	mobile_is_incremental_lift: {
		title: "Is Incremental Lift",
		description: "",
		wiki: "/wiki/split_tests__lift_tests"
	},
	pages: {
		estimate_time: (c) => (exists(c) ? 1 : 0),
		title: "Pages",
		description:
			"List all pages we are expected to launch this solution on. Select from dropdown or manually input all that apply",
		placeholder: "All",
		selections: [
			"Home",
			"Entrance",
			"Category",
			"Product",
			"Cart",
			"Checkout",
			"Register",
			"Login",
			"Landing",
			"Confirmation"
		]
	},
	products: {
		estimate_time: (c) => (exists(c) ? 1 : 0),
		title: "Products",
		description: "Please document the products that we are allowed to launch on",
		placeholder: "All",
		selections: [
			"Launch on all products",
			"Only launch on specific products:",
			"Launch on all products EXCEPT the specific products:"
		]
	},
	stages: {
		estimate_time: (c) => (exists(c) ? 1 : 0),
		title: "Stages",
		description: "List all stages where we should launch this solution.",
		placeholder: "All",
		selections: [
			"Bounce - First page view",
			"Page View -  After a page is viewed",
			"Product View - After an item is viewed",
			"Pre-Cart - Before item is added to cart",
			"Active Cart - After item is added to cart",
			"Pre-Checkout - Before user reaches checkout page",
			"Active-Checkout - After user reaches checkout page",
			"Post-Purchase - After an item is bought",
			"Pre-Submit Only - Before email is submitted",
			"Post-Submit Only - After email is submitted",
			"Anonymous Visitor - Anonymous email capture",
			"Lifecycle - Specify point in lifecycle",
			"Coupon Corrector - After X failed coupon attempts"
		]
	},
	visitors: {
		estimate_time: (c) => (exists(c) ? 1 : 0),
		validate: (c, fields) => {
			let lead_source = fields["Strategy"].fields.find((f) => f.field_type_id === "lead_source");
			return lead_source && !c.match(/api/i) && lead_source.content.match(/consumer database\+/i)
				? "Suppressing existing users with a client API is recommended for Consumer Database+ campaigns."
				: "";
		},
		title: "Visitors",
		description: "Describe what type of visitors we are expected to launch this solution on.",
		placeholder: "All",
		selections: [
			"Not logged in",
			"Logged in",
			"New visitor: first session, cookie based",
			"Return visitor: sessions > 1, cookie based",
			"Return customer: sale seen, cookie based",
			"Data Feed: List/API provided by client"
		],
		wiki: "/wiki/creating_csd_tasks_and_new_major_versions#goto_anonymous_visitor_id_faqs"
	},
	has_start_date_time: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Has Custom Start Time",
		description: ""
	},
	has_end_date_time: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Has Custom End Time",
		description: ""
	},
	has_geo_restriction: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Has Geo Restriction",
		description: ""
	},
	has_traffic_restriction: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Has Traffic Restriction",
		description: ""
	},
	traffic_sources: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Traffic Sources",
		description: "List all traffic sources that we are allowed to launch on",
		selections: ["Direct", "Search", "Paid Search", "Affiliate", "Facebook", "Social Media"]
	},
	onsite_ab_test: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Is AB Test",
		description:
			"This includes ABC or ABCD tests... etc. Any situation where we're testing multiple config variations"
	},
	post_click_boostbar: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Post-Click Boostbar",
		description: "Should we display a boost bar after the user clicks our CTA?"
	},
	"design_1+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Variation",
		description: "",
		selections: ["1", "2", "3", "4", "5", "6"]
	},
	"design_2+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"design_3+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"design_4+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"design_5+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"design_6+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"design_7+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"design_8+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"design_9+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"design_10+variation": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"design_1+design": {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Design",
		description: "",
		selections: [
			"Designer's Choice",
			"Floating Overlay",
			"Full-Screen Takeover",
			"Sidebar: Left (full height, no tab)",
			"Sidebar: Right (full height, no tab)",
			"Side-Slider (with tab)",
			"Boost Bar",
			"In-Page"
		]
	},
	estimate_time: (c) => (exists(c) ? 2 : 0),
	"design_1+dynamic_elements": {
		title: "Dynamic Elements",
		description: "",
		placeholder: "None",
		selections: ["Top Item in Cart", "Top 3 Items in Cart", "Recommended Product(s)", "Product Page Item"]
	},
	"design_1+p2": {
		title: "Page 2",
		description: "Copy, content, features or other requests for Page 2",
		selections: [
			"None",
			"Thank you, your email has been sent. Please allow 2-3 minutes for delivery. Just in case, check your spam folder."
		]
	},
	email_dynamic_content: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Email Dynamic Content",
		description: "",
		placeholder: "None",
		selections: [
			"Cart Contents (1)",
			"Cart Contents (3)",
			"Product Recs (3)",
			"Subtotal",
			"Subtotal + Discount + Discounted Subtotal"
		]
	},
	opt_in_required: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Opt In Required",
		description: "Typically this is required to be GDPR compliant"
	},
	cart_rebuilder: {
		estimate_time: (c) => (exists(c) ? 10 : 0),
		title: "Cart Rebuilder",
		description:
			"Re-add items from cart if they are missing. Cart rebuilder discovery ticket is required beforehand to confirm this is possible."
	},
	datapass: {
		validate: (c, fields) => {
			let lead_source = fields["Strategy"].fields.find((f) => f.field_type_id === "lead_source");
			return lead_source && lead_source.content.match(/consumer database\+/i) && exists(c)
				? "Datapass is NOT allowed for Consumer Database+ campaigns."
				: "";
		},
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Datapass",
		description: "Toggle to true if the client wants data sent to an email service with an API."
	},
	email_ab_test: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Is AB Test",
		description: "This includes any situation where we're testing multiple config variations"
	},
	"email_1+attempt": {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Attempt",
		description: "",
		selections: ["1", "2", "3", "4", "5", "6"]
	},
	"email_2+attempt": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"email_3+attempt": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"email_4+attempt": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"email_5+attempt": {
		estimate_time: (c) => (exists(c) ? 2 : 0)
	},
	"email_1+coupon_code": {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Coupon Code",
		description: ""
	},
	discount_description: {
		title: "Discount Description",
		description: "Describe what the shopper gets from clicking on our CTA",
		selections: ["X% Off", "$X Off Orders $Y+", "Free gift", "Free Shipping"]
	},
	coupon_expiration_date: {
		title: "Coupon Expiration",
		description: ""
	},
	apply_coupon_on_p2: {
		title: "Apply Coupon on P2",
		description: "Should we automatically apply the discount after the user clicks our CTA and gets a coupon?"
	},
	strategy_notes: {
		title: "Strategy Notes",
		description: ""
	},
	onsite_cta_destination: {
		title: "Onsite CTA Destination",
		description: "Which page should we take the user to after they click our CTA?",
		selections: ["Same Page", "Cart Page", "Checkout Page", "The page where we can apply a coupon"]
	},
	email_cta_destination: {
		title: "Email CTA Destination",
		description: "Which page should we take the user to after they click our email CTA?",
		selections: ["Home Page", "Cart Page", "Checkout Page", "The page where we can apply a coupon"]
	},
	tracking_notes: {
		title: "Tracking Notes",
		description: ""
	},
	sale_suppress: {
		title: "Post-Sale Suppression",
		description: "Suppress user from ALL campaigns for this long after an order is completed",
		selections: ["1 Month", "6 Months", "1 Year", "2 Years"]
	},
	launch_suppress: {
		title: "Launch Suppress",
		description:
			"Suppress user from ALL other campaigns for this long after the campaign (modal or in-page) is displayed (seen on screen)",
		selections: ["1 Week", "2 Weeks", "30 Days"]
	},
	click_suppress: {
		title: "Click Suppress",
		description: "Suppress user from ALL other campaigns for this long after the (modal or in-page) CTA is clicked",
		selections: ["1 Week", "2 Weeks", "30 Days"]
	},
	suppression_scope: {
		title: "Suppression Scope",
		description: "The campaigns that should be supressed on launch/click",
		selections: [
			"Company-Wide (Default - Use Company Cookie)",
			"This SiteID Only (Do Not Suppress Other Campaigns)",
			"Custom: (*** Please Describe Custom Rules Here ***)"
		]
	},
	ignore_other_suppressions: {
		title: "Ignore Other Suppressions",
		description: "Ignore suppression cookies set by other campaigns"
	},
	suppression_notes: {
		title: "Suppression Notes",
		description: "",
		wiki: "/wiki/creating_csd_tasks_and_new_major_versions#goto_suppressions_types_and_examples"
	},
	desktop_launch_animation: {
		title: "Desktop Launch Animation",
		description: "",
		selections: [
			"None",
			"Expand",
			"Fade In",
			"Slide In From Top",
			"Slide In From Left",
			"Slide In From Right",
			"Slide In From Bottom"
		]
	},
	desktop_launch_delay: {
		title: "Launch Delay",
		description: "",
		selections: ["0 sec", "1 sec", "5 sec", "10 sec", "30 sec", "1 min", "5 min"]
	},
	desktop_incremental_lift_percentages: {
		title: "Incremental Lift Percentages",
		description: "",
		selections: [
			"10% Upsellit / 90% Control",
			"20% Upsellit / 80% Control",
			"30% Upsellit / 70% Control",
			"40% Upsellit / 60% Control",
			"50% Upsellit / 50% Control",
			"60% Upsellit / 40% Control",
			"70% Upsellit / 30% Control",
			"80% Upsellit / 20% Control",
			"90% Upsellit / 10% Control"
		]
	},
	desktop_launch_notes: {
		title: "Desktop Launch Notes",
		description: ""
	},
	mobile_launch_animation: {
		title: "Mobile Launch Animation",
		description: "",
		selections: [
			"None",
			"Expand",
			"Fade In",
			"Slide In From Top",
			"Slide In From Left",
			"Slide In From Right",
			"Slide In From Bottom"
		]
	},
	mobile_launch_delay: {
		title: "Launch Delay",
		description: "",
		selections: ["0 sec", "1 sec", "5 sec", "10 sec", "30 sec", "1 min", "5 min"]
	},
	mobile_incremental_lift_percentages: {
		title: "Incremental Lift Percentages",
		description: "",
		selections: [
			"10% Upsellit / 90% Control",
			"20% Upsellit / 80% Control",
			"30% Upsellit / 70% Control",
			"40% Upsellit / 60% Control",
			"50% Upsellit / 50% Control",
			"60% Upsellit / 40% Control",
			"70% Upsellit / 30% Control",
			"80% Upsellit / 20% Control",
			"90% Upsellit / 10% Control"
		]
	},
	mobile_launch_notes: {
		title: "Mobile Launch Notes",
		description: ""
	},
	start_datetime: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		title: "Start Time",
		description: "Select the start time for this campaign, in PT"
	},
	start_date: {
		title: "Start Date (PT)",
		description: "Select the start date for this campaign, in PT"
	},
	start_time: {
		title: "Start Time (PT)",
		description: "Select the start time for this campaign, in PT"
	},
	end_datetime: {
		estimate_time: (c) => (exists(c) ? 2 : 0),
		validate: (c, fields) => {
			let start_datetime = fields["Engagement"].fields.find((f) => f.field_type_id === "start_datetime");
			return start_datetime &&
				exists(c) &&
				exists(start_datetime.content) &&
				new Date(start_datetime.content) >= new Date(c)
				? "End date must be after start date"
				: "";
		},
		title: "End Time",
		description: "Select the end time for this campaign, in PT"
	},
	end_date: {
		title: "End Date (PT)",
		description: "Select the end date for this campaign, in PT"
	},
	end_time: {
		title: "End Time (PT)",
		description: "Select the end time for this campaign, in PT"
	},
	geo_locations: {
		title: "IP Geo Locations",
		description:
			"Enter the country if it should ONLY launch when the user's country is found by IP. If we are limited to US/English pages, list that in Engagement Notes"
	},
	engagement_notes: {
		title: "Engagement Notes",
		description: ""
	},
	onsite_design_notes: {
		title: "On-Site Design Notes",
		description: ""
	},
	boost_bar_copy: {
		title: "Boost Bar Copy",
		description: "What should the boost bar say?",
		selections: [
			"Your discount will be applied at checkout",
			"Your X% discount will be applied at checkout",
			"Add $X to unlock your Y% discount",
			"Use coupon code XXXXX at checkout to get Y% off"
		]
	},
	"design_1+version": {
		title: "Version",
		description: "",
		selections: ["a", "b", "c", "d", "e", "f"]
	},
	"design_1+headline": {
		title: "Headline",
		description: ""
	},
	"design_1+notes": {
		title: "Notes",
		description: ""
	},
	number_of_attempts: {
		title: "Number of Attempts",
		description: "How many emails do we send?",
		selections: ["0 (No Emails)", "1", "2", "3", "4", "5", "6"]
	},
	datapass_details: {
		title: "Datapass Details",
		description: "List the platform and required information. Each platform has different terminology and requirements",
		selections: [
			"Active Campaign (Sub-domain, API Token, List ID)",
			"Attentive (API Key, Signup Source ID)",
			"Braze (API Key)",
			"Bronto (Token, List, Merchant)",
			"Campaign Monitor (API Key, List ID)",
			"Clever Reach (Access Token, Group ID)",
			"Constant Contact (Access Token)",
			"Cordial (API Key, List Key)",
			"Eloqua (Site Name, Username, Password)",
			"Emarsys (Client Name, Client Secret)",
			"Emma (Public Key, Private Key, Account ID)",
			"HubSpot (API Key)",
			"iContact (API ID, Username, Password)",
			"Klaviyo (API Key, List ID)",
			"Listrak (API Key, API Secret, List ID)",
			"Mail Chimp ()API Key List ID",
			"Marketing Cloud (Salesforce) (subdomain, client ID, client secret, CDO External Key)",
			"Marketo (Account ID, Client ID, Client Secret)",
			"Maropost (Account ID API Key List ID)",
			"Pardot (Username Password, Client ID, Client Secret, Business Unit ID, List ID)",
			"PostUp (Username, Password)",
			"Radar (Sub-domain, API Key, Destination)",
			"Responsys (Login URL, Username, Password)",
			"SailThru (API Key, Secret)",
			"Selligent (API Key, Organization)",
			"Yotpo (Store ID, Secret Key, List ID)",
			"Zaius (Public API Key, Private API Key, List ID)"
		]
	},
	business_mailing_address: {
		title: "Business Mailing Address",
		description:
			"We are required by law to list the client's physical mailing address in our email footers. Please enter the address in this field"
	},
	email_notes: {
		title: "Email Notes",
		description: ""
	},
	"email_1+version": {
		title: "Version",
		description: "",
		selections: ["a", "b", "c", "d", "e", "f"]
	},
	"email_1+send_time": {
		title: "Send Time",
		description: "Time since abandonment",
		selections: ["Immediately", "1 hour", "1 day", "2 days", "3 days", "4 days", "5 days"]
	},
	"email_1+email_hero_copy": {
		title: "Hero Copy",
		description: ""
	},
	"email_1+notes": {
		title: "Notes",
		description: ""
	},
	blocker_enable: {
		title: "Enable Ad Extension Manager?",
		placeholder: "Enable internal only view",
		description: "",
		selections: ["Enable internal only view", "Enable Client Viewing", "None"],
		wiki: "/wiki/coupon_experience_platform"
	},
	blocker_desktopmobile: {
		title: "Enable desktop and mobile?",
		description: "",
		placeholder: "Both",
		selections: ["Desktop only", "Mobile Only"],
		wiki: "/wiki/coupon_experience_platform"
	},
	blocker_lifttest: {
		title: "Add Incremental Lift Test?",
		placeholder: "None",
		description: "",
		selections: [
			"10% Upsellit / 90% Control",
			"20% Upsellit / 80% Control",
			"30% Upsellit / 70% Control",
			"40% Upsellit / 60% Control",
			"50% Upsellit / 50% Control",
			"60% Upsellit / 40% Control",
			"70% Upsellit / 30% Control",
			"80% Upsellit / 20% Control",
			"90% Upsellit / 10% Control"
		]
	},
	blocker_monitor: {
		title: "Which Extensions should we monitor?",
		description: "",
		placeholder: "All",
		selections: [
			'All',
			'None',
			'BeFrugal',
			'BestPrice',
			'Capital One (Partial Block)',
			'Cently',
			'CNET',
			'Coupert',
			'CouponBirds',
			'CouponCabin',
			'Honey',
			'Karma',
			'Klarna',
			'OctoShop',
			'PriceBlink',
			'Rakuten',
			'RetailMeNot',
			'Slickdeals'
		  ]
	},
	blocker_block: {
		title: "Which Extensions should we block?",
		description: "",
		placeholder: "All",
		selections: [
			'All',
			'None',
			'BeFrugal',
			'BestPrice',
			'Capital One (Partial Block)',
			'Cently',
			'CNET',
			'Coupert',
			'CouponBirds',
			'CouponCabin',
			'Honey',
			'Karma',
			'Klarna',
			'OctoShop',
			'PriceBlink',
			'Rakuten',
			'RetailMeNot',
			'Slickdeals'
		  ]
	},
	blocker_notes: {
		title: "Ad Extension Manager Notes",
		description: ""
	},
	analytics_enable: {
		title: "Enable Client Viewing?",
		description: "",
		// placeholder: "Enable Client Viewing",
		// selections: ["Enable internal only view", "Enable Client Viewing", "None"],
		wiki: "/wiki/coupon_experience_platform"
	},
	analytics_alert: {
		title: "Enable email alerts",
		description: "",
		placeholder: "On",
		selections: ["On", "Off"]
	},
	analytics_individual: {
		title: "Email alert: Individual or all coupons?",
		description: "By default we report on all coupon, but can have a report on individual coupons",
		placeholder: "All coupons",
		selections: ["Coupons individually", "All coupons"]
	},
	analytics_threshold: {
		title: "Email alert: Invalid threshold",
		description: "Don't email if there are fewer than this many invalid coupons",
		placeholder: "2",
		selections: ["2", "10", "25", "50", "100", "250", "500"]
	},
	analytics_window: {
		title: "Email alert: Invalid window",
		description: "Look for coupon use over this many days",
		placeholder: "1 Day",
		selections: ["1 Day", "2 Days", "3 Days"]
	},
	analytics_total: {
		title: "Email alert: Total Dropoff",
		description: "Only email if the total amount of coupons used drops below this amount",
		placeholder: "25%",
		selections: ["5%", "10%", "15%", "20%", "25%", "30%", "35%", "45%", "50%"]
	},
	analytics_valid: {
		title: "Email alert: Valid Dropoff",
		description: "Only email if the total amount of VALID coupons used drops below this amount",
		placeholder: "25%",
		selections: ["5%", "10%", "15%", "20%", "25%", "30%", "35%", "45%", "50%"]
	},
	analytics_invalid: {
		title: "Email alert: Invalid Dropoff",
		description: "Only email if the total amount of INVALID coupons used drops below this amount",
		placeholder: "25%",
		selections: ["5%", "10%", "15%", "20%", "25%", "30%", "35%", "45%", "50%"]
	},
	analytics_notes: {
		title: "Analytics Notes",
		description: ""
	},
	preview: {
		title: "Preview",
		description: "Paste a Dropbox raw image link here to render the image preview in reader mode."
	},
	mockups: {
		title: "Mockups",
		description: "Link to graphic mockups"
	},
	deliverables: {
		title: "Deliverables",
		description: "Link to graphic deliverables"
	},
	copy: {
		title: "Copy",
		description: "Link to copy document"
	},
	client_assets: {
		title: "Client Assets",
		description: "Items provided by the client"
	},
	templates: {
		title: "Templates",
		description: "Prebuilt templates used by Graphics to expedite development.",
		selections: [
			"TT - Basic [Desktop]",
			"TT - Basic [Mobile]",
			"TT - Carousel Recommendations [Desktop]",
			"TT - Carousel Recommendations [Mobile]",
			"TT - Cart Sidebar [Desktop]",
			"TT - Countdown",
			"TT - Full Screen",
			"TT - One Product [Desktop]",
			"TT - One Product [Mobile]",
			"TT - Sidebar Recs [Desktop]",
			"TT - Sidebar [Desktop]",
			"TT - Slideshow [Desktop]",
			"TT - Social Proof - Three Products from Cart [Desktop]",
			"TT - Three Products from Cart [Desktop]",
			"TT - Three Products from Cart [Mobile]",
			"TT - Three Recommendations [Desktop - Boostbar]",
			"TT - Three Recommendations [Desktop - Horizontal]",
			"LC - Alternate Page 2",
			"LC - Availability Alert",
			"LC - Basic",
			"LC - Basic Cart - Three Items",
			"LC - Basic Cart [Mobile]",
			"LC - Basic [Mobile]",
			"LC - Mobile Text Message [Mobile]",
			"LC - One Product from Cart [Discount]",
			"LC - Opt In",
			"LC - Phone Number Capture",
			"LC - Sidebar cart",
			"LC - Sidebar cart [Mobile]",
			"In-Page - Basic",
			"In-Page - Email Signup [In-page]",
			"In-Page - Recommendations & Minicart Sidebar [Sidebar]",
			"In-Page - Recommendations Slider [Carousel]",
			"LC Email - Basic",
			"LC Email - Three Products [Discount]",
			"EM - Basic",
			"EM - Three Products [Discount]",
			"Anon - Basic",
			"Anon - Three Products [Discount]"
		]
	},
	assets_notes: {
		title: "Assets Notes",
		description: "Notes on items provided by the client"
	},
	copy_notes: {
		title: "Copy Notes",
		description: "Notes for the Copy writer."
	},
	design_notes: {
		title: "Design Notes",
		description: "Notes for the Designers."
	},
	dev_notes: {
		title: "Dev Notes",
		description: "Notes for the Developers."
	},
	qa_notes: {
		title: "QA Notes",
		description: "Notes for the QA Analysts."
	},
	testing_instructions: {
		title: "Testing Instructions",
		description: "Describe the steps needed to launch the campaign",
		wiki: "/wiki/testing_flags"
	}
};